import pestSpray from '../assets/ProductDetail/pest-spray.png'
import starterKit1 from '../assets/ProductDetail/starter-kit.png'
import fertiliser from '../assets/ProductDetail/fertiliser.png'
import starterKit2 from '../assets/ProductDetail/starter-kit2.png'
import pumpkin from '../assets/ProductDetail/pumpkin.png'

export const products = [
    {
      name: "Natural green pest spray - 100% No chemical",
      price: "10",
      imageUrl: pestSpray,
    },
    {
      name: "Gardening Material Starter Pack for home grower",
      price: "15",
      imageUrl: starterKit1,
    },
    {
      name: "Homemade organic fertiliser 100% Natural",
      price: "20",
      imageUrl: fertiliser,
    },
    {
      name: "Gardening Material Starter Pack for home grower",
      price: "25",
      imageUrl: starterKit2,
    },
    {
      name: "Organic Farm fresh pumpkin 100% chemical free",
      price: "30",
      imageUrl: pumpkin,
    },
    {
      name: "Product 6",
      price: "35",
      imageUrl: "https://via.placeholder.com/150",
    },
    {
      name: "Product 7",
      price: "40",
      image: "https://via.placeholder.com/150",
    },
    {
      name: "Product 8",
      price: "45",
      image: "https://via.placeholder.com/150",
    },
  ];

export const courses = [
  {
    name: "Indoor Plant Propagation Techniques",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Vertical Gardening for Urban Spaces",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Organic Indoor Plant Care and Maintenance",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Advanced Indoor Plant Lighting Strategies",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Product 5",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Product 6",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Product 7",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
  {
    name: "Product 8",
    instructor: "Emily Greene",
    thumbnailUrl: "https://via.placeholder.com/150",
  },
];

export const description = `Our Heavy-Duty Garden Shovel is a must-have tool for every gardener and farmer. Designed for durability and efficiency, this shovel makes digging, planting, and landscaping tasks easier and more comfortable.
Features:
Material: Made from high-quality, rust-resistant steel, ensuring longevity and durability.
Handle: Ergonomic wooden handle with a comfortable grip, reducing hand and wrist fatigue.
Benefits:
Versatile Use: Ideal for a variety of gardening tasks, including digging holes, planting trees and shrubs, moving soil, and more.
Durable Construction: Built to withstand heavy use in all weather conditions, ensuring a long service life.`;



